<template>
  <el-dialog :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh" width="90%">
    <el-card class="l-condition">
      <el-form :inline="true" :model="condition">
        <el-form-item label="资产分类">
          <el-cascader v-model="codeArr" :props="categoryProps" :options="categoryList" @change="getPageData" clearable placeholder="---不限---" style="width:180px;"></el-cascader>
        </el-form-item>
        <el-form-item label="资产状态">
          <l-select v-model="condition.assetState" :data="$constant.easset_state_list" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="使用状态">
          <l-select v-model="condition.useState" :data="$constant.easset_usestate_list" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="管控状态">
          <l-select v-model="condition.hasProject" :data="hasProject_list" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="资产编号|资产名称"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider></el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list">
        <template slot="assetName" slot-scope="scope">
          <el-button type="text" @click="$site.view_easset_detail(scope.row.assetId)">{{ scope.row.assetName }}</el-button>
        </template>
        <template slot="assetState" slot-scope="scope">
          <span v-if="scope.row.assetState == 1">在建</span>
          <span v-else-if="scope.row.assetState == 2">启用</span>
          <span v-else-if="scope.row.assetState == 3">封存</span>
          <span v-else-if="scope.row.assetState == 4">处置</span>
          <span v-else-if="scope.row.assetState == 5">报废</span>
        </template>
        <template slot="regular" slot-scope="scope">
          <el-button type="warning" icon="el-icon-plus" size="mini" @click="add(scope.row)">添加管控</el-button>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>

    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight * 0.9 - 280 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '资产编号', prop: 'assetCode' },
          { label: '资产名称', slot: 'assetName' },
          { label: '资产分类', prop: 'categoryName' },
          { label: '品牌', prop: 'brandName' },
          { label: '购置日期', prop: 'purchaseDate', selected: false },
          { label: '管理部门', prop: 'departName' },
          { label: '管理人', prop: 'userName' },
          { label: '使用部门', prop: 'useDepartName', selected: false },
          { label: '使用人', prop: 'useUserName', selected: false },
          { label: '资产状态', slot: 'assetState', filter: a => (a ? '是' : '否') },
          { label: '管控项目', prop: 'projectCount', width: 150 },
          { label: '定期查', slot: 'regular', width: 150 }
        ]
      },
      codeArr: [],
      condition: {
        // enterpriseCode: this.userInfo.enterpriseCode,
        keyword: '',
        categoryCode: '',
        isInspected: true,
        hasProject: 'false',
        assetState: '',
        useState: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null,
      categoryList: null,
      categoryProps: {
        checkStrictly: true,
        expandTrigger: 'hover',
        value: 'categoryCode',
        label: 'categoryName'
      },
      hasProject_list: [
        { code: 'true', text: '已添加' },
        { code: 'false', text: '未添加' }
      ]
    }
  },
  mounted() {
    this.getCategoryList()
    this.getPageData()
  },
  methods: {
    getCategoryList() {
      this.$lgh.get('api/enterprise/easset/category/tree', ['enterpriseCode'], false).then(res => {
        this.categoryList = res
      })
    },
    async getPageData(pageOption) {
      if (this.codeArr.length !== 0) {
        this.condition.categoryCode = this.codeArr[this.codeArr.length - 1]
      } else {
        this.condition.categoryCode = ''
      }
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/easset/list', ['enterpriseCode', this.condition])
    },
    add(item) {
      this.visible = false
      this.onsuccess && this.onsuccess(item)
    }
  }
}
</script>

<style></style>
